import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Container from "../container"

const Wrapper = styled.div`
  height: 100vh;
  color: ${(props) => props.textColor};
  background: ${(props) => props.bgColor};
  display: flex;
`

const Heading = styled.h1.attrs({ className: `styled-h1` })`
  text-align: center;
`

const StyledContainer = styled(Container)`
  align-self: center;
`

const Hero = (props) => (
  <Wrapper bgColor={props.bgColor} textColor={props.textColor}>
    <StyledContainer>
      <Heading dangerouslySetInnerHTML={{ __html: props.title }} />
    </StyledContainer>
  </Wrapper>
)

Hero.propTypes = {
  bgColor: PropTypes.any,
  textColor: PropTypes.any,
  title: PropTypes.string,
}

export default Hero
