import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import { graphql, useStaticQuery } from "gatsby"

import Meta from "../components/meta"
import Link from "../components/link"
import Row from "../components/grid/row"
import Col from "../components/grid/column"
import Hero from "../components/page-press/hero"
import Container from "../components/container"
import Spacer from "../components/spacer"
import { contentFromRelease, sortByReleaseDate } from "../utils/press-release"

/* c8 ignore next 4 */
const StyledCol = styled(Col)`
  @media ${(props) => props.theme.smallDown} {
    margin-top: ${rem(20)};
  }
`

/* c8 ignore next 4 */
const Divider = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colorPaleGrey};
`

const PressPage = () => {
  const {
    allContentfulPress: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulPress(
        filter: { title: { eq: "Press" }, node_locale: { eq: "en-US" } }
      ) {
        nodes {
          metaTitle
          metaDescription {
            metaDescription
          }
          pressReleases {
            title
            releaseDate(formatString: "YYYY-MM-DD")
            slug
          }
          title
          keywords
        }
      }
    }
  `)

  const press = nodes.length
    ? nodes[0]
    : {
        keywords: ``,
        metaDescription: {},
        metaTitle: ``,
        pressReleases: [],
        title: ``,
      }

  const articles = press.pressReleases
    .map(contentFromRelease)
    .sort(sortByReleaseDate)

  const content = {
    meta: {
      title: press.metaTitle,
      description: press.metaDescription.metaDescription,
      keywords: press.keywords,
    },
    heroBlock: {
      title: press.title,
      /* c8 ignore next 3 */
      bgColor: (props) => props.theme.colorTerracotta,
      textColor: (props) => props.theme.colorWhite,
    },
    articles: articles,
  }

  return (
    <React.Fragment>
      <Meta {...content.meta} />

      <Hero {...content.heroBlock} />

      <Container>
        {content.articles.map((item, index) => (
          <Link to={item.url} key={index}>
            <Spacer top bottom>
              <Row>
                <Col smallUp={6}>
                  <span className="styled-h4">{item.date}</span>
                </Col>

                <StyledCol smallUp={6}>
                  <h3
                    className="styled-h4"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                </StyledCol>
              </Row>
            </Spacer>

            {index + 1 < content.articles.length && (
              <Row>
                <Col smallUp={12}>
                  <Divider />
                </Col>
              </Row>
            )}
          </Link>
        ))}
      </Container>
    </React.Fragment>
  )
}

PressPage.propTypes = {
  theme: PropTypes.any,
}

export default PressPage
